import React from 'react';
import './DatasetCard.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import BackendConnector from '../BackendConnector';

const DatasetCard = ({ 
    id, 
    title,
    authors, 
    numberOfFiles, 
    lastChangeDate, 
    downloadsNumber,
    size,
    smallDescription,
    addToHistory
}) => {
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [showSmallDescription, setShowSmallDescription] = useState(false);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                if (id) {
                    const cachedImage = localStorage.getItem(`dataset-image-${id}`);
                    if (cachedImage) setImage(cachedImage);
                    const imageData = await BackendConnector.getImage(id);
                    setImage(imageData.imageUrl);
                    localStorage.setItem(`dataset-image-${id}`, imageData.imageUrl);
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [id]);

    const getFileWord = (number) => {
        if (11 <= number % 100 && number % 100 <= 14) {
            return `${number} файлов`;
        } else { 
            const lastDigit = number % 10;
            if (lastDigit === 1) {
                return `${number} файл`;
            } else if (2 <= lastDigit && lastDigit <= 4) {
                return `${number} файла`;
            } else {
                return `${number} файлов`;
            }
        }
    };

    const handleClick = () => {
        addToHistory(`/dataset/${id}`);
        navigate(`/dataset/${id}`);
    };
    
    return (
        <>
        {title ?
            <div id='hover'>
                <div id='datasetCard'
                    onClick={handleClick}
                    onMouseEnter={() => setShowSmallDescription(true)} 
                    onMouseLeave={() => setShowSmallDescription(false)} 
                >
                    <div style={{display: 'flex'}}>
                        {image ?
                            <img id='datasetImage' src={image} alt=''></img>
                            :
                            <div className='imageLoadingAnimation' id='datasetImage'></div>
                        }
                        <h3 id='datasetTitleOnCard'>{title}</h3>
                    </div>
                    <div id='cardInfo'>
                        <p className='fieldInfo' id='authors'>{authors}</p>
                        <p className='fieldInfo' id='renewData'>Обновлён {lastChangeDate}</p>
                        <div className='rowSpaceBetween'>
                            <p className='fieldInfo'>{downloadsNumber} скачиваний</p>
                            <div className='row'>
                                <p className='fieldInfo'>{size} ({getFileWord(numberOfFiles)})</p>
                            </div>
                        </div>
                    </div>
                </div>
                {showSmallDescription &&
                    <div id='datasetCardDescription'><p>{smallDescription}</p></div>}
            </div>
        
        :
            <div id='hover'>
                <div id='datasetLoading'>
                    <div style={{display: 'flex'}}>
                        <div className='imageLoadingAnimation' id='datasetImage'></div>
                        <div className='textLoadingAnimation'></div>
                    </div>
                    <div id='cardInfo'>
                        <div className='textLoadingAnimation'></div>
                        <div className='textLoadingAnimation'></div>
                        <div className='rowSpaceBetween'>
                            <div className='textLoadingAnimation'></div>
                            <div className='row'>
                                <div className='textLoadingAnimation'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default DatasetCard;