import React, { useEffect, useState } from 'react';
import './CardsRowSection.css';
import { Topic, BackendConnector } from '../../components';

const PopularTopics = ({ topics, showAllClick, addToHistory }) => {
    const [datasetsMap, setDatasetsMap] = useState({}); // Для хранения данных по темам

    useEffect(() => {
        const loadDatasetsSequentially = async () => {
            for (const topic of topics) {
                try {
                    const data = await BackendConnector.topicSearch(topic);
                    setDatasetsMap(prev => ({ ...prev, [topic]: data }));
                } catch (error) {
                    console.error(`Error loading topic "${topic}":`, error);
                }
            }
        };

        loadDatasetsSequentially();
    }, []);

    return (
        <div id="highlyRatedDatasetsSection">
            {topics.map(topic => (
                <Topic
                    key={topic}
                    topic={topic}
                    datasets={datasetsMap[topic] || []} // Передача данных, если они уже загружены
                    showAllClick={showAllClick}
                    addToHistory={addToHistory}
                />
            ))}
        </div>
    );
};

export default PopularTopics;
